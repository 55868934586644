import React from 'react'
import JobApplicationsComponent from '../../../../../components/employerDashboard/jobs/applications'
import Layout from '../../../../../components/employerDashboard/layout'
import { Router } from '@reach/router'

const JobsAppplicationPage = ({ location }) => {
  let jobId = location?.pathname?.split('/')[3]
  return (
    <Layout title="Applications | MedReps.com" location={location}>
      <Router basepath="/employer-dashboard/jobs">
        <JobApplicationsComponent path={`/${jobId}/applications`} />
      </Router>
    </Layout>
  )
}
export default JobsAppplicationPage
